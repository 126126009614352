<template>
  <div>
    <div style="float: left">
      <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="$emit('toggleCollapsed')" />
    </div>
    <div style="float: right">
      <a-popover>
        <template slot="content">
          <a-list
            bordered
            :dataSource="notificationItems"
            style="width: 400px; height: 240px; overflow: auto; margin-bottom: 12px"
          >
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta :description="item.content">
                <div slot="title">{{ item.title }} - {{ item.create_time }}</div>
              </a-list-item-meta>
              <a-button size="small" @click="readNotification(item)">已读</a-button>
            </a-list-item>
          </a-list>

          <a-button type="primary" block @click="readAllNotification(item)">全部已读</a-button>
        </template>
        <a-badge size="small" :count="notificationItems.length">
          <a-avatar icon="bell" />
        </a-badge>
      </a-popover>

      <a-dropdown :trigger="['click']">
        <span class="trigger">
          {{ username }}
          <a-icon type="down" />
        </span>
        <a-menu slot="overlay">
          <a-menu-item @click="$router.push('/user/set_password')">
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item @click="logout">
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { notificationList, notificationRead, notificationReadAll } from "@/api/system";
import Cookies from "js-cookie";

export default {
  name: "Headbar",
  props: ["collapsed", "username"],
  inject: ["reloadPage"],
  data() {
    return {
      notificationItems: [],
      interval: null,
    };
  },
  methods: {
    initData() {
      this.getNotification();
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.getNewNotification();
        }, 3000);
      }
    },
    getNotification() {
      notificationList().then((data) => {
        this.notificationItems = data.results;
      });
    },
    getNewNotification() {
      notificationList().then((data) => {
        for (const notificationItem of data.results) {
          if (this.notificationItems.findIndex((item) => item.id === notificationItem.id) === -1) {
            this.$notification[notificationItem.type]({
              message: notificationItem.title,
              description: notificationItem.content,
              duration: null,
            });
          }
        }

        this.notificationItems = data.results;
      });
    },
    logout() {
      Cookies.remove("access");
      Cookies.remove("refresh");
      this.$router.push("/user/login");
    },
    onChangeWarehouse(item) {
      this.currentWarehouse = item.name;
      this.$store.commit("setWarehouse", item.id);
      this.reloadPage();
    },
    readNotification(item) {
      notificationRead({ id: item.id }).then((data) => {
        this.$message.success("已读成功");
        this.getNotification();
      });
    },
    readAllNotification() {
      notificationReadAll().then(() => {
        this.$message.success("全部已读成功");
        this.getNotification();
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
</style>
